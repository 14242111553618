import 'whatwg-fetch'
import { useState, useEffect, useMemo, useReducer, useCallback } from 'react';
import moment from 'moment-timezone';
import { orderBy } from 'lodash';
import { createInstance } from 'localforage';

const canAbort = (() => {
  const g =
    typeof self !== "undefined" ? self :
      typeof window !== "undefined" ? window :
        typeof global !== "undefined" ? global :
          undefined

  if (!g) return false;
  if (typeof g.AbortController === 'undefined') return false;
  if (typeof g.AbortSignal === 'undefined') return false;
  return true;
})()

export function load(url, params) {
  const meta = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `API-Key ${process.env.API_KEY}`
    }
  }

  let controller;
  if (canAbort) {
    controller = new AbortController();
    meta.signal = controller.signal;
  }

  let query = url;
  if (params) {
    query += `?${Object.keys(params).map(key => `${key}=${JSON.stringify(params[key])}`).join('&')}`
  }

  const request = fetch(query, meta)
    .then(response => {
      const success = response.ok;
      return response.json()
        .then(json => success ? json : Promise.reject(json));
    })

  if (controller) {
    request.cancel = () => controller.abort();
  }

  return request;
}

export const getQueryStatus = (...statuses) => {
  // idle > loading > success|error
  return statuses.reduce((lastStatus, nextStatus) => {
    if (!lastStatus) return nextStatus;
    if (lastStatus === 'error') return lastStatus;
    if (lastStatus === 'loading') return lastStatus;
    return nextStatus;
  }, null)
}

export const parseEnv = env => {
  if (typeof env !== 'string') return env;
  if (!env || !env.length) return;
  try {
    return JSON.parse(env);
  } catch (e) { /* ignore */ }
}
export const parseOffice = officeId => {
  let id = parseInt(officeId);
  if (!isNaN(id)) return id;
  try {
    id = JSON.parse(officeId);
  } catch (e) { /* ignore */ }
  return id;
}

export const SKATER = 'skater';
export const GOALIE = 'goalie';

export const GENDERS = [
  'Male',
  'Female',
  'Integrated',
]

export const SCHEDULE_TYPES = [
  'Playoffs',
  'League',
  'Placement',
  'Exhibition',
  'Tournament',
  'Championship',
  'Cup',
]

export const OFFICE_TYPES = [
  'League',
  'Association',
  'District',
  'Region',
  'Branch',
]

export const isLeague = schedule =>
  ['Placement', 'Exhibition', 'League', 'Playoffs'].includes(schedule.type)

export const getStatuses = (member) => {
  const status = [];
  if (!member) return [];
  if (member.isCaptain) status.push('C');
  if (member.isAlternate) status.push('A');
  if (member.isAffiliate) status.push('AP');
  if (member.isOverage) status.push('OA');
  if (member.isUnderage) status.push('UA');
  if (member.isStarter) status.push('S');
  if (member.suspension) status.push('X');
  if (member.isReleased) status.push('Rls');
  return status;
}

const winLossMappings = {
  regulation: 'common.enums.winloss.regulation',
  overtime: 'common.enums.winloss.overtime',
  shootout: 'common.enums.winloss.shootout',
  forfeit: 'common.enums.winloss.forfeit',
  doubleForfeit: 'common.enums.winloss.doubleForfeit',
}

const isDoubleForfeit = stats => stats?.winLossType === 'forfeit' && stats?.gameResult === 'tie'

export const getWinLossType = teamStats => {
  if (!teamStats.length) return null;

  let winLossType;
  if (isDoubleForfeit(teamStats[0]) || isDoubleForfeit(teamStats[1])) {
    winLossType = 'doubleForfeit';
  } else {
    winLossType = teamStats[0]?.winLossType || teamStats[1]?.winLossType;
  }

  return winLossMappings[winLossType] || null;
};

const sortOptions = {
  sensitivity: 'base',
  ignorePunctuation: true,
  numeric: true,
}

export const compareName = (a, b) => {
  if (!a && !b) return 0;
  if (a && !b) return -1;
  if (b && !a) return 1;
  return a.name.localeCompare(b.name, undefined, sortOptions)
}

export const compareType = (a, b) => {
  if (!a && !b) return 0;
  if (a && !b) return -1;
  if (!a && b) return 1;

  const aType = SCHEDULE_TYPES.findIndex(type => type === a.type);
  const bType = SCHEDULE_TYPES.findIndex(type => type === b.type);
  const typeDiff = aType - bType;
  if (typeDiff !== 0) return typeDiff;

  return compareName(a, b);
}

const officeOrder = [
  'League',
  'Association',
  'Region',
  'District',
  'Branch',
  'Organization',
]

export const compareOffice = (a, b) => {
  const aType = officeOrder.indexOf(a.type);
  const bType = officeOrder.indexOf(b.type);
  const typeDiff = aType - bType;
  if (typeDiff !== 0) return typeDiff;

  return compareName(a, b);
}

export const sortAlphabetical = teams =>
  teams.sort(compareName);

export const sortNumeric = (collection, value) =>
  collection.sort((a, b) => parseInt(b[value]) - parseInt(a[value]));

export const sortNumericAsc = (collection, value) =>
  collection.sort((a, b) => parseInt(a[value]) - parseInt(b[value]));

export const sortProperty = (collection, selector) =>
  collection.sort((a, b) => parseInt(selector(b)) - parseInt(selector(a)));

export const sortType = schedules =>
  schedules.sort(compareType)

export const sortOffices = offices =>
  offices.sort((a, b) => compareOffice(a, b) || compareName(a, b))

const format = (date, fmt, locale = moment.locale(), timezone = moment.tz.guess()) =>
  moment.tz(date, timezone || 'UTC').locale(locale).format(fmt);

export const formatDay = (date, locale = moment.locale(), timezone) =>
  format(date, 'dddd, ', locale, timezone);

export const formatTime = (date, locale = moment.locale(), timezone, showTimezone) =>
  format(date, `h:mm A${showTimezone ? ' z' : ''}`, locale, timezone);

export const formatFullDate = (date, locale = moment.locale(), timezone) =>
  format(date, 'll', locale, timezone);

export const formatDate = (date, locale = moment.locale(), timezone) =>
  format(date, 'MMM D', locale, timezone)

export const formatDateRange = (start, end, locale = moment.locale(), timezone) => {
  const startDate = moment(start).locale(locale);
  const endDate = moment(end).locale(locale);

  if (!startDate.isSame(endDate, 'year')) return `${formatDate(start, locale, timezone)} - ${formatFullDate(end, locale, timezone)}`
  if (!startDate.isSame(endDate, 'month')) {
    if (!moment().isSame(startDate, 'year')) return `${formatDate(start, locale, timezone)} - ${formatFullDate(end, locale, timezone)}`
    return `${formatDate(start, locale, timezone)} - ${formatDate(end, locale, timezone)}`;
  }
  if (!moment().isSame(startDate, 'year')) return `${formatDate(start, locale, timezone)} - ${format(end, 'D, YYYY', locale, timezone)}`;
  return `${formatDate(start, locale, timezone)} - ${format(end, 'D', locale, timezone)}`;
}

export const matchesGroup = schedule => group => {
  if (!schedule || !group) return false;
  return group.seasonId === schedule.seasonId && group.type === schedule.type
    && (!group.categoryIds?.length || group.categoryIds.includes(schedule.categoryId))
}

export const orderEvents = events =>
  orderBy(
    events,
    ['gameTime.period', 'gameTime.minutes', 'gameTime.seconds'],
    ['asc', 'desc', 'desc']
  )

export const displayPeriod = period => {
  return `common.enums.periods.${period}.short`
};

export const getPeriod = penalty => {
  const { period } = penalty.gameTime;
  return `common.enums.periods.${period}.long`
};

export const isSpecial = team =>
  !team || team?.name?.startsWith('TBA') || team?.name?.startsWith('Non-District Team')

export const getStorage = storeName => createInstance({
  name: 'HiSports 2018-19',
  storeName,
})

export const useStorage = (storeName, item, defaultValue) => {
  const [ value, setValue ] = useState(defaultValue)
  const [ ready, setReady ] = useState(false)

  const store = useMemo(() => getStorage(storeName), [ storeName ])

  const setInitialValue = useCallback(stored => {
    setValue(value => {
      try {
        if (typeof defaultValue === 'object') {
          return Object.assign({}, value, stored)
        }
        return stored || value;
      } catch (e) {
        console.log(e); /* eslint-disable-line no-console */
        return value;
      }
    })
  }, [ setValue, defaultValue ]);

  // load value from storage
  useEffect(() => {
    store.getItem(item)
      .then(stored => {
        setInitialValue(stored);
      })
      .finally(() => {
        setReady(true)
      })
  }, [ store, item, setInitialValue ])

  // keep storage updated on save
  useEffect(() => {
    if (!store) return;
    store.setItem(item, value);
  }, [ store, item, value ])

  return [ value, setValue, ready ];
}

export const useStoredReducer = (reducer, storeName, item, defaultValue) => {
  const [ state, dispatch ] = useReducer(reducer, defaultValue);
  const [ ready, setReady ] = useState(false);

  const store = useMemo(() => getStorage(storeName), [ storeName ])

  useEffect(() => {
    store.getItem(item)
      .then(state => {
        dispatch({ action: 'restore', state })
      })
      .finally(() => {
        setReady(true)
      })
  }, [ store, item ])

  useEffect(() => {
    if (!state) return;
    store.setItem(item, state)
  }, [ store, item, state ])

  return [ state, dispatch, ready ]
}

export const useMedia = (queries, values, defaultValue) => {
  const mediaQueryLists = queries.map(q => window.matchMedia(q));

  const getValue = useMemo(() => {
    const index = mediaQueryLists.findIndex(mql => mql.matches);
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
  }, [ mediaQueryLists, values, defaultValue ]);

  const [value, setValue] = useState(getValue);
  useEffect(() => {
    // Note: By defining getValue outside of useEffect we ensure that it has current values of hook args
    const handler = () => setValue(getValue);

    mediaQueryLists.forEach(mql => mql.addListener(handler));
    return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
  }, [ getValue, mediaQueryLists ]);

  return value;
}

export const useSorting = (sortData = null, sortOrder = null) => {
  const [currentSortData, setCurrentSortData] = useState(sortData);
  const [currentSortOrder, setCurrentSortOrder] = useState(sortOrder);

  const handleSorting = (newSortData) => {
    setCurrentSortData(newSortData);

    if (currentSortData == newSortData) {
      switch (currentSortOrder) {
        case 'DESC':
          setCurrentSortOrder('ASC');
          break;
        case 'ASC':
          // Return to default sort and order after both state were triggered
          setCurrentSortData(sortData);
          setCurrentSortOrder(sortOrder);
          break;
      }
    } else {
      setCurrentSortOrder('DESC');
    }
  }

  return { currentSortData, currentSortOrder, handleSorting }
}
